import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { HassWebHookName, TriggerableEntities } from '@prisma/client';

export interface Response {
  triggerableEntities: TriggerableEntities[];
}

@Injectable({
  providedIn: 'root',
})
export class AllTriggerableEntities extends Query<Response> {
  document = gql`
    query triggerableEntities {
      triggerableEntities {
        id
        name
        type
        createdAt
        createdBy
        updatedAt
        description
        needsUserRoles
        pricePerMinute
        entityLocation {
          id
          name
        }
        entityLocationId
        internalId
        needsSecondAuthFactor
        hassWebHooksToTrigger {
          id
          hassWebHookNameId
          hassWebhookId
        }
      }
    }
  `;
}
